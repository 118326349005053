function openUrlInNewTab(url: string): void {
  // Yeni sekmede URL aç
  const newWindow = window.open(url, "_blank");

  // Yeni pencereye odaklan
  if (newWindow) {
    newWindow.focus();
  }
}

function downloadURI(uri: string, name: string = "file") {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export { openUrlInNewTab, downloadURI };
