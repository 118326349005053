import { Stack, Typography } from "@mui/material";
import { useShow, useTranslation } from "@refinedev/core";
import {
  NumberField,
  Show,
  TextFieldComponent as TextField,
} from "@refinedev/mui";
import { ValueField } from "../../../components/pages/show/ValueField";

export const ErrorTypeShow = () => {
  const { queryResult } = useShow({});
  const { data, isLoading } = queryResult;
  const { translate } = useTranslation();
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          {"ID"}
        </Typography>
        <TextField value={record?.id ?? ""} />
        <ValueField label={translate("pages.definition.errorType.fields.category")} value={record?.categoryName} />
        <Typography variant="body1" fontWeight="bold">
          {"Code"}
        </Typography>
        <TextField value={record?.code} />
        <Typography variant="body1" fontWeight="bold">
          {"Definition"}
        </Typography>
        <TextField value={record?.definition} />
      </Stack>
    </Show>
  );
};
