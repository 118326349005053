import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import { useShow, useTranslation } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { IPartyResponse } from "../../../model/fabric/IPartyResponse";
import { IExternalPartyResponse } from "../../../model/fabric/IExternalPartyResponse";
import { Controller } from "react-hook-form";
import { useEffect } from "react";
import { IRollResponse } from "../../../model/fabric/IRollResponse";



export const RollEdit = () => {
  const {
    saveButtonProps,
    register,
    formState: { errors },
    control,
    setValue,
    refineCore: {
      queryResult
    }
  } = useForm<IRollResponse>();

  const record = queryResult?.data?.data;

  const { autocompleteProps: partyAutocompleteProps } = useAutocomplete<IPartyResponse>({
    resource: "Party",
    defaultValue: record?.externalParty?.party?.id
  });

  const { autocompleteProps: externalPartyAutocompleteProps } = useAutocomplete<IExternalPartyResponse>({
    resource: "ExternalParty",
    defaultValue: record?.externalParty?.id,
    filters: [
      {
        field: "PartyId",
        operator: "eq",
        value: record?.externalParty?.party?.id
      }
    ],
    queryOptions: {
      enabled: record?.externalParty?.party?.id != undefined
    }
  });
  
  const { translate } = useTranslation();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <FormControl>
          <Controller
            control={control}
            name="party"
            defaultValue={null as any}
            rules={{
              validate: (value) => {
                if (!value) {
                  return translate("errors.required.field");
                }
                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <Autocomplete
                id="party"
                {...partyAutocompleteProps}
                {...field}
                clearOnBlur={false}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getOptionLabel={(item) => item.code}
                isOptionEqualToValue={(option, value) =>
                  value === undefined ||
                  option?.id?.toString() === (value?.id ?? value)?.toString()
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Party"
                    margin="normal"
                    variant="outlined"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </FormControl>


        <FormControl>
          <Controller
            control={control}
            name="externalParty"
            defaultValue={null as any}
            rules={{
              validate: (value) => {
                if (value === undefined) {
                  return translate("errors.required.field");
                }
                return true;
              },
            }}
            render={({ field }) => (
              <Autocomplete
                id="externalParty"
                {...externalPartyAutocompleteProps}
                {...field}
                clearOnBlur={false}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getOptionLabel={(item) => item.code}
                isOptionEqualToValue={(option, value) =>
                  value === undefined ||
                  option?.id?.toString() === (value?.id ?? value)?.toString()
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="External Party"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            )}
          />
        </FormControl>


        <TextField
          {...register("code", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.code}
          helperText={(errors as any)?.code?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Code"}
          name="code"
          required
        />

        <TextField
          {...register("rowOrder", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          inputProps={{ inputMode: 'numeric' }}
          label={"Row Order"}
          name="rowOrder"
          required
        />

        <TextField
          {...register("rollAmount", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          inputProps={{ inputMode: 'numeric' }}
          label={"Roll Amount"}
          name="rollAmount"
          required
        />

        <TextField
          {...register("rollAmountType", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Roll Amount Type"}
          name="rollAmountType"
          required
        />

        <TextField
          {...register("fabricType", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Fabric Type"}
          name="fabricType"
          required
        />

        <TextField
          {...register("rawMaterialCode", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Raw Material Code"}
          name="rawMaterialCode"
          required
        />

        <TextField
          {...register("rawMaterialName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Raw Material Name"}
          name="rawMaterialName"
          required
        />

        <TextField
          {...register("warehouse", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Warehouse"}
          name="warehouse"
          required
        />

        <TextField
          {...register("colorCode", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Color Code"}
          name="colorCode"
          required
        />

        <TextField
          {...register("colorName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Color Name"}
          name="colorName"
          required
        />

        <TextField
          {...register("dyehouse", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Dyehouse"}
          name="dyehouse"
          required
        />

      </Box>
    </Edit>
  );
};
