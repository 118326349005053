import { useState, useEffect } from 'react';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';

// Define the printer status type
interface PrinterStatus {
    isReadyToPrint: boolean;
    errors: string;
}

// Utility function to format date (dd.MM.yyyy)
const formatDate = (date: Date | undefined): string => {
    if (date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    return "";

};

// Utility function to format datetime (dd.MM.yyyy HH:mm:ss)
const formatDateTime = (date: Date | undefined): string => {
    if (date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    }

    return "";
};

// Create a custom hook
export const usePrintBarcode = () => {
    const [printerStatus, setPrinterStatus] = useState<PrinterStatus | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isPrinting, setIsPrinting] = useState<boolean>(false);

    const printBarcode = async (
        createDate: Date,
        externalPartyCode: string,
        rawMaterialCode: string,
        fabricType: string,
        colorCode: string,
        rawMaterial: string,
        colorName: string,
        rollCode: string,
        topSayisi: number,
        partyCode: string,
        dyeHouse: string,
        barcodeRollCode: string,
        rollid: string,
        approvementCount: number,
        errorEntryStartTime: Date | undefined,
        errorEntryEndTime: Date | undefined,
        isVerified: boolean
    ) => {
        try {
            
            // Create a new instance of the object
            const browserPrint = new ZebraBrowserPrintWrapper();

            // Select default printer
            const defaultPrinter = await browserPrint.getDefaultPrinter();

            // Set the printer
            browserPrint.setPrinter(defaultPrinter);

            // Check printer status
            const status: PrinterStatus = await browserPrint.checkPrinterStatus();

            setPrinterStatus(status);

            // Check if the printer is ready
            if (status.isReadyToPrint) {
                // ZPL script to print a simple barcode
                const zpl = `^XA
  ^CI28
  ^PW600^LL900^LH0,0
  
  ^FO30,25^ADN,20,10^FDO.T:${formatDate(createDate)}^FS
  ^FO410,25^ADN,20,10^FDY.T:${formatDate(new Date())}^FS
  ^FO200,60^A0N,70,70^FD${externalPartyCode}^FS
  
  ^FO30,120^ADN,20,10^FD${rawMaterialCode}^FS
  ^FO300,120^ADN,20,10^FD${fabricType}^FS
  ^FO450,120^ADN,20,10^FD${colorCode}^FS
  
  ^FO30,160^ADN,20,10^FD${rawMaterial}^FS
  ^FO400,160^ADN,20,10^FD${colorName}^FS
  
  ^FO30,200^GB570,1,4^FS
  
  ^FO30,220^ADN,20,10^FDTop No: ${rollCode} T.S:${topSayisi}^FS
  ^FO30,260^ADN,20,10^FDParti No: ${partyCode}^FS
  ^FO30,300^ADN,20,10^FD${dyeHouse}^FS
  
  ^FO30,380^GB570,1,4^FS
  
  ^FO30,400^ADN,20,10^FDHATA TESPiTi SONRASI CIKTI^FS
  
  ^FO30,440^GB570,1,4^FS
  
  ^FO70,470^BY3,3,120^BCN,120,Y,N,N
  ^FD${barcodeRollCode}^FS
  ^FO30,640^GB570,1,4^FS
  ^FO200,540^BQN,2,4
  ^FDQA,https://kumas.imteks.com.tr/roll/showexternal/${rollid}}^FS
  
  ^FO30,855^ADN,20,10^FDK.S.: ${approvementCount} Kontrol^FS
  ^FO30,880^ADN,20,10^FDB.T.: ${formatDateTime(errorEntryStartTime)}^FS
  ^FO30,905^ADN,20,10^FDT.T.: ${formatDateTime(errorEntryEndTime)}^FS
  ^FO30,935^ADN,20,10^FDOD.: ${isVerified ? "ONAYLI" : "ONAYLI DEGIL"}^FS
  
  ^XZ`;

                // Print the barcode
                browserPrint.print(zpl);
                setIsPrinting(true);
            } else {
                setError('Printer is not ready');
                console.log('Error/s', status.errors);
            }
        } catch (err: any) {
            setError(err.message || 'An error occurred');
        } finally {
            setIsPrinting(false);
        }
    };

    return { printerStatus, error, isPrinting, printBarcode };
};