import { Authenticated, GitHubBanner, Refine } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import { I18nProvider } from "@refinedev/core";
import { useTranslation } from "react-i18next";
import {
  RefineSnackbarProvider,
  useNotificationProvider,
} from "@refinedev/mui";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { AppIcon } from "./components/app-icon";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { ThemedLayoutV2 } from "./components/layout";
import { ThemedHeaderV2 } from "./components/layout/header";
import { ThemedSiderV2 } from "./components/layout/sider";
import { ThemedTitleV2 } from "./components/layout/title";
import "./extensions/arrayExtensions";

import Dashboard from "@mui/icons-material/Dashboard";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DryCleaningIcon from "@mui/icons-material/DryCleaning";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InventoryIcon from "@mui/icons-material/Inventory";
import MapIcon from "@mui/icons-material/Map";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ExposureIcon from "@mui/icons-material/Exposure";

import {
  ErrorTypeCreate,
  ErrorTypeEdit,
  ErrorTypeList,
  ErrorTypeShow,
} from "./pages/definition/error-type";
import { ForgotPassword } from "./pages/visa/forgotPassword";
import { Login } from "./pages/visa/login";
import { Register } from "./pages/visa/register";
import { GetServiceUrl } from "./helpers/configManager";
import { dataProvider } from "./providers/data-provider";
import { authProvider } from "./providers/authProvider";
import {
  PartyList,
  PartyCreate,
  PartyShow,
  PartyEdit,
} from "./pages/fabric/party";
import { ErrorComponent } from "./components/pages/error";
import {
  ExternalPartyList,
  ExternalPartyCreate,
  ExternalPartyEdit,
  ExternalPartyShow,
} from "./pages/fabric/external-party";
import { RoleCreate, RoleList } from "./pages/visa/role";
import { RoleEdit } from "./pages/visa/role/edit";
import {
  RollList,
  RollCreate,
  RollShow,
  RollEdit,
  WaitingApprovalRollList,
} from "./pages/fabric/roll";
import { UserList, UserCreate, UserEdit } from "./pages/visa/user";
import { SystemConfigurationSettings } from "./pages/definition/systemConfiguration";
import { DashboardPage } from "./pages/dashboard";
import { accessControlProvider } from "./providers/accessControlProvider";
import { RollExternalShow } from "./pages/fabric/roll/externalshow";
import { useEffect, useState } from "react";
import {
  ErrorTypeGroupList,
  ErrorTypeGroupCreate,
  ErrorTypeGroupEdit,
} from "./pages/definition/error-type-group";
import { useFullscreen } from "./contexts/full-screnn";
import { DoneAll } from "@mui/icons-material";
import { ReportList } from "./pages/summary/report";
import "dayjs/locale/tr";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


// Eklentileri dayjs'e ekleyin
dayjs.extend(utc);
dayjs.extend(timezone);


function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider: I18nProvider = {
    translate: (key: string, options?: any) => {
      var response = t(key, options);
      if (typeof response === "string") return response;

      return key;
    },
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
              <Refine
                i18nProvider={i18nProvider}
                dataProvider={dataProvider(GetServiceUrl())}
                notificationProvider={useNotificationProvider}
                authProvider={authProvider}
                routerProvider={routerBindings}
                accessControlProvider={accessControlProvider}
                resources={[
                  {
                    name: "dashboard",
                    list: "/",
                    meta: {
                      label: "Dashboard",
                      icon: <Dashboard />,
                    },
                  },
                  {
                    name: "Definition",
                    meta: {
                      label: t("pages.definition.label"),
                      icon: <AppRegistrationIcon />,
                    },
                  },
                  {
                    name: "SystemConfiguration",
                    list: "/settings",
                    meta: {
                      canDelete: false,
                      label: t("pages.definition.systemConfiguration.label"),
                      parent: "Definition",
                      icon: <SettingsIcon />,
                    },
                  },
                  {
                    name: "ErrorType",
                    list: "/errortype",
                    create: "/errortype/create",
                    edit: "/errortype/edit/:id",
                    show: "/errortype/show/:id",
                    meta: {
                      canDelete: true,
                      label: t("pages.definition.errorType.label"),
                      parent: "Definition",
                      icon: <ErrorOutlineIcon />,
                    },
                  },
                  {
                    name: "ErrorTypeGroup",
                    list: "/errortypegroup",
                    create: "/errortypegroup/create",
                    edit: "/errortypegroup/edit/:id",
                    meta: {
                      canDelete: true,
                      label: t("pages.definition.errorTypeGroup.label"),
                      parent: "Definition",
                      icon: <ErrorOutlineIcon />,
                    },
                  },
                  {
                    name: "Fabric",
                    meta: {
                      label: t("pages.fabric.label"),
                      icon: <DryCleaningIcon />,
                    },
                  },
                  {
                    name: "Party",
                    list: "/party",
                    create: "/party/create",
                    edit: "/party/edit/:id",
                    show: "/party/show/:id",
                    meta: {
                      canDelete: true,
                      label: t("pages.fabric.party.label"),
                      parent: "Fabric",
                      icon: <LocalShippingIcon />,
                    },
                  },
                  {
                    name: "ExternalParty",
                    list: "/externalparty",
                    create: "/externalparty/create",
                    edit: "/externalparty/edit/:id",
                    show: "/externalparty/show/:id",
                    meta: {
                      canDelete: true,
                      label: t("pages.fabric.externalParty.label"),
                      parent: "Fabric",
                      icon: <InventoryIcon />,
                    },
                  },
                  {
                    name: "Roll",
                    list: "/roll",
                    create: "/roll/create",
                    edit: "/roll/edit/:id",
                    show: "/roll/show/:id",
                    meta: {
                      canDelete: true,
                      label: t("pages.fabric.roll.label"),
                      parent: "Fabric",
                      icon: <MapIcon />,
                    },
                  },
                  {
                    name: "Roll",
                    identifier: "roll-waitingapprovals",
                    list: "/roll/waitingapprovals",
                    meta: {
                      canDelete: false,
                      label: t("pages.fabric.roll.rollwaitingapprovals"),
                      parent: "Fabric",
                      icon: <DoneAll />,
                    },
                  },
                  {
                    name: "Roll",
                    identifier: "RollExternal",
                    show: "/roll/showexternal/:id",
                    meta: {
                      canDelete: true,
                      hide: true,
                      parent: "Fabric",
                      icon: <MapIcon />,
                    },
                  },
                  {
                    name: "RollErrorType",
                    meta: {
                      canDelete: true,
                      parent: "Fabric",
                      hide: true,
                    },
                  },
                  {
                    name: "Visa",
                    meta: {
                      label: t("pages.visa.label"),
                      icon: <LockPersonIcon />,
                    },
                  },
                  {
                    name: "User",
                    list: "/user",
                    create: "/user/create",
                    edit: "/user/edit/:id",
                    meta: {
                      label: t("pages.visa.user.label"),
                      parent: "Visa",
                      icon: <PersonIcon />,
                    },
                  },
                  {
                    name: "Role",
                    list: "/role",
                    create: "/role/create",
                    edit: "/role/edit/:id",
                    meta: {
                      label: t("pages.visa.role.label"),
                      parent: "Visa",
                      icon: <FingerprintIcon />,
                    },
                  },
                  {
                    name: "ParentReport",
                    meta: {
                      label: t("pages.summary.label"),
                      icon: <AssessmentIcon />,
                    },
                  },
                  {
                    name: "Report",
                    list: "/report",
                    meta: {
                      label: t("pages.summary.report.label"),
                      parent: "ParentReport",
                      icon: <ExposureIcon />,
                    },
                  },
                ]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  useNewQueryKeys: true,
                  projectId: "T1ug9I-uCCfpg-NxMGRp",
                  disableTelemetry: true,
                }}
              >
                <Routes>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-inner"
                        fallback={<CatchAllNavigate to="/login" />}
                      >
                        <ThemedLayoutV2
                          Header={() => <ThemedHeaderV2 sticky />}
                          Title={({ collapsed }) => (
                            <ThemedTitleV2
                              collapsed={collapsed}
                              text="SUM Hata Takip Sistemi"
                              icon={<AppIcon />}
                            />
                          )}
                          Sider={ThemedSiderV2}
                        >
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    <Route index element={<DashboardPage />} />

                    <Route path="/errortype">
                      <Route index element={<ErrorTypeList />} />
                      <Route path="create" element={<ErrorTypeCreate />} />
                      <Route path="edit/:id" element={<ErrorTypeEdit />} />
                      <Route path="show/:id" element={<ErrorTypeShow />} />
                    </Route>

                    <Route path="/errortypegroup">
                      <Route index element={<ErrorTypeGroupList />} />
                      <Route path="create" element={<ErrorTypeGroupCreate />} />
                      <Route path="edit/:id" element={<ErrorTypeGroupEdit />} />
                    </Route>

                    <Route path="/party">
                      <Route index element={<PartyList />} />
                      <Route path="create" element={<PartyCreate />} />
                      <Route path="edit/:id" element={<PartyEdit />} />
                      <Route path="show/:id" element={<PartyShow />} />
                    </Route>

                    <Route path="/externalparty">
                      <Route index element={<ExternalPartyList />} />
                      <Route path="create" element={<ExternalPartyCreate />} />
                      <Route path="edit/:id" element={<ExternalPartyEdit />} />
                      <Route path="show/:id" element={<ExternalPartyShow />} />
                    </Route>

                    <Route path="/roll">
                      <Route index element={<RollList />} />
                      <Route path="create" element={<RollCreate />} />
                      <Route path="edit/:id" element={<RollEdit />} />
                      <Route path="show/:id" element={<RollShow />} />
                      <Route
                        path="waitingapprovals"
                        element={<WaitingApprovalRollList />}
                      />
                    </Route>

                    <Route path="/role">
                      <Route index element={<RoleList />} />
                      <Route path="create" element={<RoleCreate />} />
                      <Route path="edit/:id" element={<RoleEdit />} />
                    </Route>

                    <Route path="/user">
                      <Route index element={<UserList />} />
                      <Route path="create" element={<UserCreate />} />
                      <Route path="edit/:id" element={<UserEdit />} />
                    </Route>

                    <Route path="/settings">
                      <Route index element={<SystemConfigurationSettings />} />
                    </Route>

                    <Route path="/report">
                      <Route index element={<ReportList />} />
                    </Route>

                    <Route
                      path="/forbidden"
                      element={<ErrorComponent errorCode={403} />}
                    />

                    <Route
                      path="*"
                      element={<ErrorComponent errorCode={404} />}
                    />
                  </Route>
                  <Route
                    element={
                      <Authenticated
                        key="authenticated-outer"
                        fallback={<Outlet />}
                      >
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                  </Route>
                  <Route path="/roll">
                    <Route
                      path="showexternal/:id"
                      element={<RollExternalShow />}
                    />
                  </Route>
                </Routes>

                <RefineKbar />
                <UnsavedChangesNotifier />
                <DocumentTitleHandler />
              </Refine>
            </LocalizationProvider>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
