import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  DateField,
  EditButton,
  ExportButton,
  List,
  ShowButton,
  useDataGrid,
} from "@refinedev/mui";
import React from "react";
import {
  useCustomMutation,
  useInvalidate,
  useTranslation,
} from "@refinedev/core";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { DeleteButton } from "../../../components/buttons/delete";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GetCdnUrl, GetServiceUrl } from "../../../helpers/configManager";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { downloadURI } from "../../../helpers/generalHelper";
import { LoadingButton } from "@mui/lab";

export const ReportList = () => {
  const { dataGridProps } = useDataGrid({});

  const { translate } = useTranslation();

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "reportName",
        flex: 1,
        headerName: translate("pages.summary.report.fields.reportName"),
        minWidth: 200,
      },
      {
        field: "targetDate",
        flex: 1,
        headerName: translate("pages.summary.report.fields.targetDate"),
        minWidth: 200,
      },
      {
        field: "createDate",
        flex: 1,
        headerName: translate("pages.summary.report.fields.createDate"),
        minWidth: 200,
        renderCell: ({ row }) => {
          return (
            <DateField
              value={dayjs(row.createDate)}
              locales="tr"
              format="LLLL"
            />
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <IconButton
                edge="end"
                href={
                  "https://view.officeapps.live.com/op/view.aspx?src=" +
                  (GetCdnUrl() + row.url) +
                  "?t=" +
                  (new Date().getTime())
                }
                target="_blank"
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                edge="end"
                href={GetCdnUrl() + row.url}
                download={row.reportName}
                target="_blank"
              >
                <DownloadIcon />
              </IconButton>
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var { mutate } = useCustomMutation();
  const invalidate = useInvalidate();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setLoading(true);
            mutate(
              {
                url: `${GetServiceUrl()}/report/generalReport/daily`,
                method: "post",
                values: {
                  startDate: startDate?.format("YYYY-MM-DD"),
                  endDate: endDate?.format("YYYY-MM-DD"),
                },
              },
              {
                onError: (error, variables, context) => {
                  setLoading(false);
                  handleClose();
                },
                onSuccess: (data, variables, context) => {
                  setLoading(false);
                  handleClose();

                  downloadURI(
                    GetCdnUrl() + data.data.data.url,
                    data.data.data.reportName + ".xlsx"
                  );
                  invalidate({
                    resource: "Report",
                    invalidates: ["list"],
                  });
                },
              }
            );
          },
        }}
      >
        <DialogTitle>Rapor Parametreleri</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lütfen raporunu almak istediğiniz tarihi giriniz. Girilen günler
            rapora dahildir.
          </DialogContentText>
          <br />
          <Grid container columns={2} spacing={1}>
            <Grid item xs={1}>
              <DatePicker
                label="Rapor Başlangıç"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
            </Grid>
            <Grid item xs={1}>
              <DatePicker
                label="Rapor Bitiş"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Vazgeç</Button>
          <LoadingButton loading={loading} type="submit">
            Rapor Oluştur
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <List
        headerButtons={({ defaultButtons }) => (
          <>
            <ExportButton
              loading={loading}
              variant="contained"
              onClick={handleClickOpen}
            >
              Rapor Oluştur
            </ExportButton>
            {defaultButtons}
          </>
        )}
      >
        <DataGrid {...dataGridProps} columns={columns} autoHeight />
      </List>
    </>
  );
};
