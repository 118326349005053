import { Button, Chip, Dialog, DialogActions, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { DataGrid, GridCallbackDetails, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useCustomMutation, useInvalidate, useModal, useNavigation, useNotification, useTranslation } from "@refinedev/core";
import {
    EditButton,
    List,
    ShowButton,
    useDataGrid,
} from "@refinedev/mui";
import React, { useEffect, useRef, useState } from "react";
import { ReadBarcodeModal } from "./modals/readBarcodeModal";
import { BarcodeFormat, Result } from "@zxing/library";
import { GetServiceUrl } from "../../../helpers/configManager";
import { LoadingButton } from "@mui/lab";
import { getLastPartOfUrl } from "../../../helpers/stringHelper";
import { useDebounce } from 'use-debounce';


export const WaitingApprovalRollList = () => {
    const invalidate = useInvalidate();
    const [searchText, setSearchText] = useState('');
    const [debounceTime, setDebounceTime] = useState(3000); // Başlangıç debounce süresi
    const [filterValue] = useDebounce(searchText, debounceTime);
    const [remainingTime, setRemainingTime] = useState(0);
    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        if (searchText !== filterValue) {
            clearTimeout(timerRef.current);
            setRemainingTime((debounceTime / 1000) - 1);

            const updateRemainingTime = () => {
                setRemainingTime(prevTime => {
                    if (prevTime <= 0) {
                        clearTimeout(timerRef.current);
                        return 0;
                    }
                    return prevTime - 1;
                });
            };

            timerRef.current = setInterval(updateRemainingTime, 1000);

            return () => clearInterval(timerRef.current);
        }
    }, [searchText, filterValue, debounceTime]);

    const { dataGridProps } = useDataGrid({
        filters: {
            permanent: [
                {
                    operator: "or",
                    value: [
                        {
                            field: "Code",
                            operator: "contains",
                            value: filterValue
                        },
                        {
                            field: "SellerCode",
                            operator: "contains",
                            value: filterValue
                        },
                        {
                            field: "ExternalParty.Code",
                            operator: "contains",
                            value: filterValue
                        },
                        {
                            field: "ExternalParty.Party.Code",
                            operator: "contains",
                            value: filterValue
                        }
                    ]
                }
            ]
        },
        meta: {
            controller: "GetWaitingForApprovalsRoll"
        }
    });

    const { translate } = useTranslation();

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "code",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.code"),
                minWidth: 120,
            },
            {
                field: "externalParty.party.code",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.party"),
                minWidth: 120,
                valueGetter: (params: any) => params.row.externalParty.party.code
            },
            {
                field: "externalParty.code",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.externalParty"),
                minWidth: 120,
                valueGetter: (params: any) => params.row.externalParty.code
            },
            {
                field: "fabricType",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.fabricType"),
            },
            {
                field: "rawMaterialCode",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.rawMaterialCode"),
            },
            {
                field: "rawMaterialName",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.rawMaterialName"),
            },
            {
                field: "warehouse",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.warehouse"),
            },
            {
                field: "colorCode",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.colorCode"),
            },
            {
                field: "colorName",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.colorName"),
            },
            {
                field: "dyehouse",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.dyehouse"),
            },
            {
                field: "isVerified",
                flex: 1,
                headerName: translate("pages.visa.user.fields.isVerified"),
                renderCell(params) {
                    if (params.row.isVerified) {
                        return <Chip label={translate("booleanYesNo.true")} color="success" />;
                    }

                    return <Chip label={translate("booleanYesNo.false")} color="error" />;
                },
            },
            {
                field: "isInApproval",
                flex: 1,
                headerName: translate("pages.visa.user.fields.isInApproval"),
                renderCell(params) {
                    if (params.row.isInApproval) {
                        return <Chip label={translate("booleanYesNo.true")} color="success" />;
                    }

                    return <Chip label={translate("booleanYesNo.false")} color="error" />;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <ShowButton hideText recordItemId={row.id} resource="Roll" />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        []
    );

    const { show, close, visible } = useModal();
    const { open } = useNotification();
    const { show: navigateShow } = useNavigation();
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    var { mutate: sendApprovalsMutation } = useCustomMutation();
    const [approveLoading, setApproveLoading] = useState(false);
    var { mutate } = useCustomMutation();
    const [comment, setComment] = useState("");

    const onResult = (text: string, result: Result) => {
        //Barkod okunduktan sonra yapılacak işlemler. E128 ve QR için akış olmalı.

        if (result.getBarcodeFormat() == BarcodeFormat.QR_CODE) {
            close();
            navigateShow('Roll', removeBasePathFromUrl(text));
        }
        else {
            console.log("Barkod Tipi: " + result.getBarcodeFormat().toString());

            mutate({
                url: `${GetServiceUrl()}/Roll/getRollIdFromRollCode`,
                method: 'post',
                values: {
                    code: getLastPartOfUrl(text)
                }
            }
                ,
                {
                    onError: (error, variables, context) => {
                        close();
                        alert("İlgili barkoda ait top bulunamadı. Okunan değer: " + text)
                    },
                    onSuccess: (data, variables, context) => {
                        navigateShow('Roll', data.data.data!);
                        close();
                    },
                })
        }
    }

    const onError = (err: Error) => {
        open?.({
            message: err.message,
            type: "error",
            description: err.name,
            key: 'error-barcode-reader'
        });
        close();
    }


    const onApprove = () => {
        if (!selectedRows || selectedRows.length == 0) {
            alert("Hiç satır seçilmedi!");
            setApproveLoading(false);
        }
        else {
            selectedRows.forEach(element => {
                sendApprovalsMutation(
                    {
                        url: `${GetServiceUrl()}/Roll/approve`,
                        method: 'post',
                        values: {
                            Id: element
                        },
                    }
                    ,
                    {
                        onError: (error, variables, context) => {
                            open?.({
                                type: 'error',
                                key: 'send-approvals',
                                message: translate("notifications.error", { statusCode: error.statusCode }),
                                description: error.message
                            });
                            setApproveLoading(false);
                        },
                        onSuccess: (data, variables, context) => {
                            open?.({
                                type: 'success',
                                key: 'send-approvals',
                                message: translate("notifications.success")
                            });
                            setApproveLoading(false);

                            invalidate({
                                resource: "Roll",
                                invalidates: ["list"],
                            });
                        },
                    }
                );
            });
        }
    }

    const onReject = () => {
        if (!selectedRows || selectedRows.length == 0) {
            alert("Hiç satır seçilmedi!");
            setApproveLoading(false);
        }
        else {
            selectedRows.forEach(element => {
                sendApprovalsMutation(
                    {
                        url: `${GetServiceUrl()}/Roll/reject`,
                        method: 'post',
                        values: {
                            Id: element,
                            comment
                        },
                    }
                    ,
                    {
                        onError: (error, variables, context) => {
                            open?.({
                                type: 'error',
                                key: 'send-approvals',
                                message: translate("notifications.error", { statusCode: error.statusCode }),
                                description: error.message
                            });
                            setApproveLoading(false);
                        },
                        onSuccess: (data, variables, context) => {
                            open?.({
                                type: 'success',
                                key: 'send-approvals',
                                message: translate("notifications.success")
                            });
                            setApproveLoading(false);
                            denyCommentModalProps.close();
                            setComment("");
                            invalidate({
                                resource: "Roll",
                                invalidates: ["list"],
                            });
                        },
                    }
                );
            });
        }
    }


    const denyCommentModalProps = useModal();

    return (
        <List title={
            <Stack direction="row" spacing="2">
                <Typography variant="h4">
                    {translate("pages.fabric.roll.label")}
                </Typography>
                <TextField
                    value={searchText}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchText(event.target.value);
                    }}
                    id="searchText"
                    margin="normal"
                    label={"Filtre (" + remainingTime + " saniye)"}
                    name="searchText"
                    type="text"
                    autoComplete="Kumaş Kodu, Harici Parti Kodu, Satıcı Kodu,Parti Kodu"
                    placeholder="Kumaş Kodu, Harici Parti Kodu, Satıcı Kodu,Parti Kodu"
                    size="small"
                    style={{
                        width: "50%",
                        marginLeft: 10
                    }}
                />
            </Stack>
        } headerButtons={({ defaultButtons }) => (
            <>

                <Button onClick={() => show()} variant="contained">{translate("buttons.readBarcode")}</Button>
                {
                    /*
<LoadingButton variant="contained" loading={approveLoading} onClick={() => { setApproveLoading(true); onApprove(); }} color="success">{translate("pages.fabric.roll.buttons.approve")}</LoadingButton>
                <LoadingButton variant="contained" loading={approveLoading} onClick={() => {
                    if (!selectedRows || selectedRows.length == 0) {
                        alert("Hiç satır seçilmedi!");
                        setApproveLoading(false);
                    }
                    else
                        denyCommentModalProps.show();
                }} color="error">{translate("pages.fabric.roll.buttons.deny")}</LoadingButton>
                    */
                }
                {defaultButtons}
            </>

        )}
        >
            <DataGrid
                {...dataGridProps}
                columns={columns}
                autoHeight
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
                    setSelectedRows(rowSelectionModel);
                }}
            />

            <ReadBarcodeModal
                show={show}
                close={close}
                visible={visible}
                title="Barcode"
                onResult={onResult}
                onError={onError}
                oneTimeScan={true}
                options={{
                    constraints: {
                        facingMode: 'environment'
                    }
                }}
            />

            <Dialog
                open={denyCommentModalProps.visible}
                onClose={denyCommentModalProps.close} container={() => document.getElementById('fullscreen_div')}>
                <DialogContent>
                    <TextField
                        value={comment}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setComment(event.target.value);
                        }}
                        label="Yorum"
                        margin="normal"
                        variant="outlined"
                        rows={2}
                        multiline
                        sx={{ width: '100%' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={denyCommentModalProps.close}>{translate("buttons.cancel")}</Button>
                    <LoadingButton loading={approveLoading} onClick={() => { setApproveLoading(true); onReject(); }}>{translate("buttons.ok")}</LoadingButton>
                </DialogActions>
            </Dialog>
        </List>
    );
};

const removeBasePathFromUrl = (text: string) => text.replace("https://kumas.imteks.com.tr/roll/showexternal/", "");

